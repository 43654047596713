import { IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from '../../../types/general';
import { Color, FontFamily, FontSize } from '../../../styles/constants';

export interface IPanelStyleProps extends IDeviceInfoContext {
  className?: string;
}

export interface IPanelStyles {
  panel: IStyle;
  iconBack: IStyle;
}

export const getStyles = ({ className, calculateRemValue }: IPanelStyleProps): IPanelStyles => ({
  panel: [
    {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 999999,
      width: calculateRemValue(92.86),
      height: '100%',
      padding: `${calculateRemValue(4.6)} ${calculateRemValue(5.56)}`,
      color: Color.primaryTextColor,
      backgroundColor: Color.panel.backgroundColor,
      fontFamily: FontFamily.secondary,
      fontSize: calculateRemValue(FontSize.Size4),
      letterSpacing: calculateRemValue(-0.15),
      overflow: 'auto',
    },
    className,
  ],
  iconBack: {
    cursor: 'pointer',
    position: 'absolute',
    right: calculateRemValue(3),
    width: calculateRemValue(3.93),
    transform: 'rotate(180deg)',
  },
});
